<template>
  <div v-if="events">
    <h2 :class="$style.heading" v-html="title"></h2>
    <ul v-if="events.length" :class="$style.list">
      <li v-for="(event, key) in events" :key="key">
        <router-link
          :class="$style.title"
          :to="{
            name: 'CalendarEvent',
            params: {
              slug: event.eventSlug,
            },
          }"
          @click="$emit('close'); checkIsSamePath(event.eventSlug, emit)"
          @keydown.enter="$emit('close'); checkIsSamePath(event.eventSlug, emit, $event)"
        >
          {{ event.title }}
        </router-link>
        <span :class="$style.label" v-html="format(event.start)"></span>
      </li>
      <li>
        <router-link
          :to="{
            name: 'CalendarSection',
          }"
          class="more-link"
          @click="$emit('close'); checkIsSamePath('/calendar', emit)"
          @keydown.enter="$emit('close'); checkIsSamePath('/calendar', emit, $event)"
        >
          {{ link }}
        </router-link>
      </li>
    </ul>
    <div v-if="!events.length">
      <p class="body-2">There are no upcoming events.</p>
      <p>
        <router-link
          :to="{
            name: 'CalendarSection',
          }"
          class="more-link"
          @click="checkIsSamePath('/calendar', emit)"
          @keydown.enter="checkIsSamePath('/calendar', emit, $event)"
        >
          {{ link }}
        </router-link>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AppConfig, MainMenu } from '@/types/global.interface';
import { computed, inject, ref } from 'vue';
import { eventListQuery } from '@/queries/EventListQuery';
import gql from 'graphql-tag';
import { storeToRefs } from 'pinia';
import { useHandleError, useNavigation } from '@/composables/Common.js';
import { useMainStore } from '@/stores/mainStore.js';
import { useQuery } from 'villus';

interface EventDate {
  day: string;
  meridiem: string;
  month: string;
  time: string;
  timezone: string;
}

const emit = defineEmits({ close: null, isSamePath: false });
const props = withDefaults(
  defineProps<{
    limit: string;
    link: string;
    showDates?: boolean;
    title?: string;
  }>(),
  {
    limit: '3',
    link: 'Browse all events',
    showDates: false,
    title: 'Upcoming Events',
  }
);

const appConfig = inject<AppConfig>('appConfig');
const mainMenu = inject<MainMenu>('mainMenu');

const { checkIsSamePath } = useNavigation();
const { handleError } = useHandleError();
const store = useMainStore();
const { useBootstrap } = storeToRefs(store);

const events = ref([]);
const shouldSkip = ref(true);

const today = computed(() => appConfig.today.isoDate);

const { data, error } = useQuery({
  onData: data => {
    if (data && Object.keys(data).length) {
      events.value = data.eventOccurrences.nodes;
    }

    if (!data) {
      handleError('EventList', error);
    }
  },
  query: gql`
    ${eventListQuery}
  `,
  skip: shouldSkip,
  variables: {
    first: +props.limit,
    today: today,
  },
});

if (useBootstrap.value) {
  shouldSkip.value = true;
  events.value = mainMenu.events;
}

if (!useBootstrap.value) {
  shouldSkip.value = false;
}

function format(date: EventDate): string {
  return `${date.month} ${date.day}, ${date.time} ${date.meridiem} ${date.timezone}`;
}
</script>

<style lang="scss" module>
.heading {
  @include heading-5;
  margin-top: 0;
}

.label {
  @include body-2;
  display: block;
  font-size: 1rem;
  text-align: center;
  text-transform: none;
}

.list {
  margin: 0;
  padding: 0;

  li {
    line-height: 1;
    list-style: none;
    margin-top: 2rem;

    &:last-of-type {
      margin-top: 1rem;
    }
  }
}

.title {
  @include body-1;
  display: inline-block;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

@media (min-width: $break-point) {
  .label {
    font-size: 0.875rem;
    text-align: left;
  }

  .title {
    font-size: 1rem;
  }

  :global(.mainnav) {
    .title {
      font-size: 1.25rem;
    }

    .label {
      font-size: 1rem;
    }
  }
}
</style>
