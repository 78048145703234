import { defineStore } from 'pinia';

// TODO: GUGG Typescript
// interface State {
//   announcement: String;
//   cart: Array;
//   currentSlide: Array;
//   dataLoaded: Boolean;
//   isKeyboardUser: Boolean;
//   overlay: Boolean;
//   routing: Boolean;
//   shown: Array;
//   token: String;
//   useBootstrap: Boolean;
//   yPos: Number;
// }

export const useMainStore = defineStore('mainStore', {
  state: () => ({
    announcement: '',
    cart: [],
    collectionArtworks: [],
    currentSlide: [],
    dataLoaded: false,
    isKeyboardUser: false,
    overlay: false,
    routing: false,
    shown: [],
    savedPosition: false,
    secondaryFixed: false,
    token: '',
    useBootstrap: false,
    yPos: 0
  }),
  actions: {
    hideItem(obj) {
      this.shown.splice(this.shown.indexOf(obj), 1);
    },
    setBoolean(value) {
      this[value] = !this[value];
    },
    setListItem({ type, id, value }) {
      this[type] = [...this[type].filter(item => item.id !== id), { id, value }];
    },
    setValue({ type, value }) {
      this[type] = value;
    },
    showItem(obj) {
      this.shown.push(obj);
    },
    emptyCart() {
      this.cart = [];
    },
    updateCart(obj) {
      if (obj.checked) {
        this.cart.push(obj.file);
      }

      if (!obj.checked) {
        for (let i = 0; i < this.cart.length; i++) {
          if (this.cart[i].uid === obj.file.uid) {
            this.cart.splice(i, 1);
          }
        }
      }
    }
  }
});
