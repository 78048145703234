<template>
  <div
    ref="search"
    :class="$style.background"
    :aria-modal="active && 'true'"
    :role="active && 'dialog'"
    @click.prevent="$emit('search-state', !active)"
  >
    <div :class="$style.frame">
      <div :class="$style.wrapper">
        <SearchForm @mounted="mounted" @search-state="updateSearchState"></SearchForm>
      </div>
      <button
        aria-label="Close"
        :class="$style.close"
        type="button"
        :ga4-event='`{"click_type": "search", "component": "SearchModal", "content_group": "search", "gtm_tag": "interaction", "link_text": "(close)"}`'
        @click.prevent="$emit('search-state', false)"
      >
        <IconClose></IconClose>
      </button>
    </div>
  </div>
</template>

<script setup>
import IconClose from '@/assets/svgs/IconClose.svg?component';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import SearchForm from '@/components/search/SearchForm.vue';
import { useA11y } from '@/composables/Common.js';
import { useMainStore } from '@/stores/mainStore.js';

defineProps({
  active: {
    type: Boolean,
    required: true,
  },
});

const store = useMainStore();
const { setValue } = store;
const { trapFocus } = useA11y();

const search = ref(null);

const emit = defineEmits({
  'search-state': null,
});

onBeforeUnmount(() => {
  trapFocus();
  document.documentElement.classList.remove('noscroll');
  setValue({ type: 'isKeyboardUser', value: false });
});

onMounted(() => {
  document.documentElement.classList.add('noscroll');
});

function mounted() {
  trapFocus(search.value);
}

function updateSearchState(state) {
  emit('search-state', state);
}
</script>

<style lang="scss" module>
.background {
  background: $white;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
}

.close {
  background: transparent;
  border: 0;
  cursor: pointer;
  height: 2.5rem;
  outline: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 2rem;
  width: 2.5rem;

  svg {
    fill: $gray-1;
  }
}

.frame {
  margin: 0 auto;
  max-width: 870px;
  position: relative;
  width: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  padding: 1rem;
}

@media (min-width: $break-point) {
  .close {
    right: -1rem;
  }

  .wrapper {
    padding: 0;
  }
}
</style>
