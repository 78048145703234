<template>
  <footer
    id="gugg-footer"
    aria-label="Footer"
    :class="[$style.footer, 'dark-bg']"
    role="contentinfo"
  >
    <div v-for="(row, idx) in footerNav.rows" :key="idx" :class="$style.row">
      <div :class="get_row_class(row)">
        <div v-for="(block, indx) in row.blocks" :key="indx" :class="get_block_class(block)">
          <h3 v-if="block.heading">{{ block.heading }}</h3>
          <span v-if="block.type === 'text' && block.text" v-html="block.text"></span>
          <PreparedAnchor
            v-if="block.type === 'text' && block.more.more_link && block.more.more_link_text"
            class="more-link"
            :ga4-data='`{"click_type": "navigation","component": "FooterNav","gtm_tag": "navigation","link_text": "${block.more.more_link_text}","link_url": "${block.more.more_link}"}`'
            :to="block.more.more_link"
            :behavior="block.more.more_link_behavior"
          >
            {{ block.more.more_link_text }}
          </PreparedAnchor>
          <ul
            v-if="
              (block.type === 'link_list' ||
                block.type === 'legalese' ||
                block.type === 'social') &&
              block.links &&
              block.links.length
            "
          >
            <li v-for="(link, index) in block.links" :key="index">
              <PreparedAnchor
                :ga4-data='`{"click_type": "navigation","component": "FooterNav","gtm_tag": "navigation","link_text": "${link.link_text}","link_url": "${link.link}"}`'
                :to="link.link"
                :behavior="link.link_behavior"
              >
                {{ link.link_text }}
              </PreparedAnchor>
            </li>
          </ul>
          <p v-if="block.type === 'legalese'">
            &copy; {{ appConfig.today.year }} The Solomon R. Guggenheim Foundation
          </p>
          <div
            v-if="block.type === 'logo'"
            :class="{ [$style.logoGrid]: block.heading?.includes('Partners') }"
          >
            <picture v-for="(logo, key) in block.logos" :key="key">
              <img
                :alt="logo.alt"
                :src="logo.src"
                :class="{ [$style.unesco]: block.heading?.includes('Unesco') }"
                loading="lazy"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { AppConfig } from '@/types/global.interface';
import { inject, useCssModule } from 'vue';
import PreparedAnchor from '@/components/common/PreparedAnchor.vue';

interface Block {
  heading?: string;
  links?: Array<{
    link: string;
    link_behavior: string;
    link_text: string;
  }>;
  logo?: {
    alt: string;
    src: string;
  };
  more?: {
    more_link: string;
    more_link_behavior: string;
    more_link_text: string;
  };
  text?: string;
  type: string;
}

interface Blocks {
  blocks: Array<Block>;
}

interface FooterNav {
  rows: Array<Blocks>;
}

const appConfig = inject<AppConfig>('appConfig');
const footerNav = inject<FooterNav>('footerNav');

const style = useCssModule();

function get_block_class(block: Block): string {
  return style[block.type];
}

function get_row_class(row: Blocks): string {
  if ('logo' === row['blocks'][0]['type']) {
    return `container ${style.logos}`;
  }

  return 'container grid';
}
</script>

<style lang="scss" module>
.footer {
  margin-top: 2rem;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h3 {
    @include body-1;
    margin-top: 0;
  }

  li {
    list-style: none;
    margin-top: 1rem;
  }

  ul {
    margin-left: 0;
    margin-top: 2rem;
    padding-left: 0;
  }

  :global(.more-link) {
    margin-top: 1rem;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.legalese {
  grid-column: span 12;

  ul {
    column-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;

    li:nth-child(-n + 2) {
      margin-top: 0;
    }
  }

  p {
    margin-top: 2rem;
  }
}

.link_list {
  grid-column: span 6;
  margin-top: 4rem;

  &:nth-child(-n + 2) {
    margin-top: 0;
  }
}

.logo {
  > div {
    margin-top: 2rem;
  }

  img {
    height: px-to-rem(82);
  }

  svg {
    margin-top: 2rem;
    height: px-to-rem(82);
  }
}

.logoGrid {
  column-gap: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  margin-bottom: 3rem;
  row-gap: 2rem;

  img {
    height: 2.5rem;

    &.unesco {
      height: px-to-rem(82);
      max-width: initial;
    }
  }
}

.logos {
  > div {
    margin-top: 0;
  }
}

.row {
  border-bottom: 1px solid $gray;
  padding: 3rem 1rem;

  &:first-of-type {
    padding-top: 6rem;
  }

  &:last-of-type {
    padding-bottom: 6rem;
  }
}

.social {
  grid-column: span 12;
  margin-top: 4rem;

  ul {
    column-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.text {
  grid-column: span 12;
  margin-top: 4rem;

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  span {
    p:first-of-type {
      margin-top: 2rem;
    }
  }

  :global(.button-secondary) {
    margin-top: 0;
  }
}

:global(.container) {
  > .text {
    &:first-of-type {
      margin-top: 0;
    }
  }
}

@media (min-width: $break-point) {
  .footer {
    margin-top: 8rem;
  }

  .legalese {
    display: flex;

    ul {
      display: flex;
      gap: 2rem;
      margin-top: 0;

      li {
        margin-top: 0;
      }
    }

    p {
      margin-left: auto;
      margin-top: 0;
    }
  }

  .link_list {
    grid-column: span 3;
    margin-top: 0;
  }

  .logoGrid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  .more {
    white-space: pre-wrap;
  }

  .social {
    grid-column: span 4;
    margin-top: 0;

    ul {
      display: block;
    }
  }

  .text {
    grid-column: span 4;
    margin-top: 0;
  }
}
</style>
