<template>
  <div :class="theClass">
    <h2 v-if="data.heading && data.display_heading === '1'" class="heading-5">
      {{ data.heading }}
    </h2>
    <ul v-if="data.type === 'link_list' && data.links && data.links.length">
      <li v-for="(link, index) in data.links" :key="index" class="space-32-above">
        <PreparedAnchor
          class="child"
          :to="link.link"
          :behavior="link.link_behavior"
          @click="
            sendEvent(link.link_text, link.link);
            checkIsSamePath(link.link, emit);
          "
          @keydown.enter="
            sendEvent(link.link_text, link.link);
            checkIsSamePath(link.link, emit, $event);
          "
        >
          {{ link.link_text }}
        </PreparedAnchor>
      </li>
    </ul>
    <div v-if="data.type === 'text' && data.text" v-html="data.text"></div>
    <PreparedAnchor
      v-if="data.more.more_link && data.more.more_link_text"
      class="more-link"
      :to="data.more.more_link"
      :behavior="data.more.more_link_behavior"
      @click="
        sendEvent(data.more.more_link_text, data.more.more_link);
        checkIsSamePath(data.more.more_link, emit);
      "
      @keydown.enter="
        sendEvent(data.more.more_link_text, data.more.more_link);
        checkIsSamePath(data.more.more_link, emit, $event);
      "
    >
      {{ data.more.more_link_text }}
    </PreparedAnchor>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import PreparedAnchor from '@/components/common/PreparedAnchor.vue';
import { useAnalytics, useNavigation } from '@/composables/Common.js';

const emit = defineEmits(['isSamePath']);
const props = defineProps<{
  data: {
    display_heading: string;
    heading: string;
    links: Array<{
      link: string;
      link_behavior: string;
      link_text: string;
    }>;
    more: {
      more_link: string;
      more_link_behavior: string;
      more_link_text: string;
    };
    text: string;
    type: string;
  };
}>();

const { checkIsSamePath } = useNavigation();
const { ga4Event } = useAnalytics();

const theClass = computed(() => {
  return (props.data.heading && props.data.display_heading === '0') || !props.data.heading
    ? 'no-heading'
    : '';
});

function sendEvent(str: string, url: string): void {
  ga4Event({
    click_type: 'navigation',
    component: 'SubNav',
    gtm_tag: 'navigation',
    link_text: str,
    link_url: url,
  });
}
</script>
